import './TeamManangement.scss';
import { Button, Container, Modal } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import React, { useContext, useMemo, useState } from 'react';
import { Maybe } from '@property-folders/contract';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { useQuery } from '@tanstack/react-query';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { ButtonVariant } from 'react-bootstrap/types';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { EditTeamModal } from '~/pages/settings/tabs/components/EditTeamModal';
import { Avatar } from '@property-folders/components/dragged-components/Avatar';

export function TeamManagement() {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [filter, setFilter] = useState('');
  const [modalTeam, setModalTeam] = useState<Maybe<{ id: number, type: number, name: string, action: 'edit' | 'delete' }>>(undefined);

  const { data: unfilteredItems, refetch } = useQuery({
    queryKey: ['settings-team-list', entityPhpInfo?.entityId || -1],
    queryFn: async context => {
      if (!entityPhpInfo?.entityId) return undefined;
      const result = await LegacyApi.ajax<{ items: TeamListItem[] } & BaseAjaxResponse>('team_get_list', {
        'EntityID': entityPhpInfo.entityId.toString()
      }, context.signal);
      return result.items.map(item => ({
        id: item.TeamID,
        ...item
      }));
    }
  });
  const items = useMemo(() => {
    const substr = filter.toLowerCase();
    return unfilteredItems
      ?.filter(item => item.Name.toLowerCase().indexOf(substr) >= 0)
      ?.sort((a, b) => a.Name.localeCompare(b.Name)) || [];
  }, [unfilteredItems, filter]);

  const closeModal = () => {
    setModalTeam(undefined);
  };

  const deleteTeamHandler = async (id: number) => {
    await LegacyApi.ajax<BaseAjaxResponse>('team_delete', {
      TeamID: id.toString()
    });
    await refetch();
    closeModal();
  };

  return <Container fluid className='h-100 position-relative d-flex flex-column flex-start g-0'>
    {modalTeam?.action === 'delete' && <ConfirmModal
      title='Delete Team'
      onCancel={closeModal}
      onConfirm={() => deleteTeamHandler(modalTeam.id)} confirmLabel='Delete' confirmVariant='danger'
    >
      <p>Are you sure you wish to delete the team <strong>{modalTeam.name}</strong>?</p>
    </ConfirmModal>}
    {modalTeam?.action === 'edit' && entityPhpInfo?.entityId && <EditTeamModal
      teamId={modalTeam.id}
      teamType={modalTeam.type}
      entityId={entityPhpInfo.entityId}
      onClose={closeModal}
      onSave={async () => {
        await refetch();
        closeModal();
      }}
    />}
    <div className='d-flex flex-wrap flex-row px-3 pt-3 mb-3 mb-lg-1 gap-2'>
      <div className='flex-grow-1 d-flex flex-row justify-content-end'>
        <Button
          variant='primary'
          size='lg'
          title='Add team'
          onClick={() => setModalTeam({ id: 0, type: 0, name: 'New Team', action: 'edit' })}
          style={{ paddingTop: 'calc(0.5rem - 0.5px)', paddingBottom: 'calc(0.5rem - 1px)' }}
        >
          <Icon name='add' variant='outlined' icoClass='fs-4 mb-1 me-2'></Icon>
          Add Team
        </Button>
      </div>
      <div style={{ flexGrow: 999 }}>
        <SearchBar
          placeholder='Search for a team'
          onSearch={term => setFilter(term)}
          className='w-100'
          constrainWidth={false}
        />
      </div>
    </div>
    <LazyInfiniteTableList
      storageKey={'EntitySettingsTeams'}
      hasNextPage={false}
      items={items}
      isFetching={!unfilteredItems}
      rowClick={row => setModalTeam({ id: row.TeamID, type: row.Type, name: row.Name, action: 'edit' })}
      columns={[{
        label: 'Name',
        rowMajor: row => row.Name
      },{
        label: 'Belongs To',
        rowMajor: row => row.OwnerID && row.OwnerName
          ? <div className='belongs-to'><Avatar name={row.OwnerName} entityName='' agentId={row.OwnerID} /></div>
          : ''
      },
      {
        label: 'Type',
        rowMajor: row => {
          switch (row.Type) {
            case 1: return 'Sales';
            case 2: return 'PM';
            default: return '';
          }}
      },
      {
        label: 'Members',
        rowMajor: row => row.MemberCount.toString()
      }]}
      rowActions={[{
        label: 'Edit',
        action: row => setModalTeam({ id: row.TeamID, type: row.Type, name: row.Name, action: 'edit' })
      }, {
        label: 'Delete',
        action: row => setModalTeam({ id: row.TeamID, type: row.Type, name: row.Name, action: 'delete' }),
        if: row => !row.OwnerID
      }]}
    />
  </Container>;
}

function ConfirmModal(props: React.PropsWithChildren<{
  title: string,
  onCancel: () => void,
  confirmVariant: ButtonVariant,
  confirmLabel: string,
  onConfirm: () => Promise<void>,
}>) {
  return <Modal show={true} onHide={props.onCancel}>
    <Modal.Header>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={props.onCancel}>Cancel</Button>
      <AsyncButton variant={props.confirmVariant} onClick={props.onConfirm}>{props.confirmLabel}</AsyncButton>
    </Modal.Footer>
  </Modal>;
}

interface TeamListItem {
  TeamID: number,
  EntityID: number,
  Name: string,
  Type: number,
  MemberCount: number,
  OwnerID?: number,
  OwnerName?: string
}

import './EditTeamModal.scss';
import { Button, FloatingLabel, Form, Modal, Spinner } from 'react-bootstrap';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import React, { useEffect, useMemo, useState } from 'react';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { Maybe } from '@property-folders/contract';
import { isEqual } from 'lodash';
import {
  validateChange,
  ValidationNode
} from '@property-folders/common/yjs-schema/property/validation/process-validation';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { ValidationErrorLabel } from '~/pages/settings/tabs/components/ValidationErrorLabel';
import clsJn from '@property-folders/common/util/classNameJoin';
import useDetectSticky from '@property-folders/components/hooks/useDetectSticky';
import { Predicate } from '@property-folders/common/predicate';
import { MemberOption, UserTeamSelector } from '~/pages/settings/tabs/components/UserTeamSelector';

interface LoadTeamResponse extends BaseAjaxResponse {
  data: {
    TeamID: number;
    EntityID: number;
    Name: string;
    Type: number;
    OwnerID?: number;
    Members: MemberOption[];
  };
}

export function EditTeamModal({
  entityId,
  onClose,
  onSave,
  teamId,
  teamType
}: {
  entityId: number,
  onClose: () => void,
  onSave: () => Promise<void>,
  teamId: number,
  teamType: number
}) {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [init, setInitData] = useState<Maybe<LoadTeamResponse>>(undefined);
  const [latest, setLatestData] = useState<Maybe<LoadTeamResponse>>(undefined);
  const [headerIsStuck, headerRef] = useDetectSticky(undefined, undefined, false);
  const [footerIsStuck, footerRef] = useDetectSticky(undefined, undefined, true);
  const [checking, setChecking] = useState(false);
  const [remoteNameValidationResult, setRemoteNameValidationResult] = useState('');
  const [showValidation, setShowValidation] = useState(false);

  const unchanged = useMemo(() => {
    return isEqual(init, latest);
  }, [init, latest]);

  useEffect(() => {
    if (teamId < 1) {
      setInitData({ success: true, data: { TeamID: teamId, EntityID: entityId, Name: '', Members: [], Type: 0 } });
      setLatestData({ success: true, data: { TeamID: teamId, EntityID: entityId, Name: '', Members: [], Type: 0 } });
      setLoading(false);
      return;
    }
    LegacyApi.ajax<LoadTeamResponse>('team_get_details', { TeamID: teamId.toString() })
      .then(data => {
        setInitData(data);
        setLatestData(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [teamId]);

  useEffect(() => {
    setRemoteNameValidationResult('');
    if (!latest?.data?.Name) return;
    if (latest?.data?.Name === init?.data?.Name) return;

    setChecking(true);
    const ac = new AbortController();
    const timeoutHandle = setTimeout(() => {
      if (ac.signal.aborted) return;
      AjaxPhp.teamCheckName({
        teamId: teamId,
        entityId: entityId,
        name: latest.data.Name,
        signal: ac.signal
      })
        .then(result => {
          if (ac.signal.aborted) return;
          setChecking(false);
          if (result?.success) return;
          setRemoteNameValidationResult(result?.message || 'Invalid Name');
        })
        .catch(err => {
          if (ac.signal.aborted) return;
          setChecking(false);
          setRemoteNameValidationResult('Invalid Name');
        });
    }, 300, ac.signal);

    return () => {
      clearTimeout(timeoutHandle);
      ac.abort();
    };
  }, [latest?.data?.Name]);

  const validationResult = useMemo(() => {
    return validateChange(
      latest || { data: {}, entities: [] },
      {
        _type: 'Map',
        data: {
          _type: 'Map',
          Name: { _type: 'string', _required: true }
        }
      },
      {
        data: {
          Name: { _required: true, _minimum: 1, _maximum: 256 }
        }
      },
      {},
      []
    );
  }, [latest]);
  const valid = validationResult._validationResult.valid && !checking && !remoteNameValidationResult;
  if (!validationResult._validationResult.valid) {
    // frontend debugging
    console.error('validationResult', validationResult);
  }

  function getValidationErrors(...path: string[]) {
    let current = validationResult;
    for (const item of path) {
      current = current?.[item];
    }
    return (current as ValidationNode | undefined)
      ?._validationResult
      ?.errors || [];
  }

  const nameErrors = useMemo(() => {
    return [
      ...getValidationErrors('data', 'Name'),
      remoteNameValidationResult
    ].filter(Predicate.isTruthy);
  }, [remoteNameValidationResult, validationResult]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowValidation(true);

    if (!valid) {
      return;
    }

    setSaving(true);
    setErrorMessage('');

    AjaxPhp.saveTeam({
      teamId,
      // @ts-ignore
      formData: new FormData(e.currentTarget)
    })
      .then(response => {
        if (response?.success) {
          return onSave();
        }

        if (response?.message) {
          setErrorMessage(response.message);
          return;
        }

        console.error(response);
        setErrorMessage('There was an error saving changes. Please try again.');
      })
      .catch(console.error)
      .finally(() => setSaving(false));
  };

  return <Modal show={true} onHide={saving ? undefined : onClose} size='lg' backdrop='static' keyboard={false}>
    {loading
      ? <>
        <Modal.Header closeButton>
          <Modal.Title>Edit {getTeamLabel(teamType)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center align-items-center p-5'>
          <Spinner animation='border'/>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={saving} variant='outline-secondary' onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </>
      : (init && latest) ? <Form onSubmit={onSubmit} noValidate>
        <Modal.Header ref={headerRef} closeButton={!saving} className={clsJn('sticky bg-white', headerIsStuck && 'stuck')} data-stick='top'>
          <Modal.Title>Edit {getTeamLabel(teamType)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type='hidden' name='EntityID' value={entityId} />
          <FloatingLabel label={'Team Name'}>
            <Form.Control
              type='text'
              name='Name'
              placeholder={''}
              defaultValue={latest.data.Name}
              disabled={Boolean(latest.data.OwnerID)}
              readOnly={Boolean(latest.data.OwnerID)}
              onChange={e => {
                setShowValidation(true);
                const newValue = e.currentTarget.value || '';
                setLatestData(cur => {
                  if (!cur) return cur;
                  const clone = structuredClone(cur);
                  clone.data.Name = newValue;
                  return clone;
                });
              }}
              isInvalid={showValidation && Boolean(nameErrors.length || remoteNameValidationResult)}
              onBlur={() => setShowValidation(true)}
            />
            {checking
              ? <div className='d-flex flex-row align-items-center'><Spinner animation='border' size='sm' className='text-muted me-1' /><span className='text-muted'>Checking...</span></div>
              : <ValidationErrorLabel
                label={'Team Name'}
                errors={nameErrors}
                message={remoteNameValidationResult}
                show={showValidation}
                useSpacer={true}
              />}
          </FloatingLabel>

          <div className='mt-3'>
            <h6>Members</h6>
            <UserTeamSelector
              name='Members'
              defaultValue={latest.data.Members}
              mode={{ type: 'add-to-team', teamId, entityId }}
              onChange={values => {
                setLatestData(cur => {
                  if (!cur) return cur;
                  const clone = structuredClone(cur);
                  clone.data.Members = values;
                  return clone;
                });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer ref={footerRef} className={clsJn('sticky bg-white', footerIsStuck && 'stuck')} data-stick='bot'>
          {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          <Button disabled={saving} variant='outline-secondary' onClick={onClose}>Cancel</Button>
          <SpinnerButton type='submit' processing={saving} disabled={checking || unchanged || !valid}>Save</SpinnerButton>
        </Modal.Footer>
      </Form> : <div>Failed to load</div>}
  </Modal>;
}

function getTeamLabel(type: number) {
  switch (type) {
    case 1: return 'Sales Team';
    case 2: return 'PM Team';
    default: return 'Team';
  }
}

export function ValidationErrorLabel({ label: labelRaw, errors, message: messageOverride, show: showRaw, useSpacer }: {
  label?: string,
  errors: string[],
  message?: string,
  show?: boolean,
  useSpacer?: boolean
}) {
  const first = errors.at(0);
  const show = showRaw === undefined ? true : showRaw;
  if (!first || !show) {
    return useSpacer
      ? <small className='d-inline-block'></small>
      : <></>;
  }

  const label = labelRaw || 'This';
  const message = (() => {
    if (messageOverride) return messageOverride;
    switch (first) {
      case 'required':
        return `${label} is required.`;
      case 'abnacn':
        return `${label} must be a valid ABN / ACN.`;
      case 'nzbn':
        return `${label} must be a valid NZBN.`;
      case 'bn':
        return `${label} must be a valid Business or Company Number`;
      case 'email':
        return `${label} must be a valid email address.`;
      case 'phone':
        return `${label} must be a valid phone number.`;
      case 'unique':
        return `${label} must be unique.`;
      default:
        return `${first} unhandled error type.`;
    }
  })();

  return <small className='text-danger'>{message}</small>;
}

import { Col, Container, Row } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import * as Y from 'yjs';
import { JsonEditor } from '~/components/JSONEditor';
import { MaterialisedPropertyData, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import React, { useEffect, useMemo, useState } from 'react';
import { Properties } from '@property-folders/common/client-api/properties';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';

export function ExploreYdoc() {
  const { transId } = useReactRouterData();
  const [yDoc, setYDoc] = useState<Y.Doc | null>(null);
  const [rootKey, setRootKey] = useState<SingleValue<{ value: PropertyRootKey, label: string }>>({
    value: PropertyRootKey.Data,
    label: 'Data'
  });
  const [updates, setUpdates] = useState<{ value: Uint8Array, date: Date, author: any, clock: number }[]>([]);
  const [ydocClock, setYdocClock] = useState<number>(-1);
  const [selectedUpdate, setSelectedUpdate] = useState<SingleValue<{ value: any; label: string }>>();

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({});
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot);
  const rootLabel = 'Properties';
  const updatedBreadcrumb = useMemo(() => [
    ...([{ label: rootLabel, href: '/properties/' }]),
    {
      label: headlineVal || 'Property Overview',
      href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}`
    },
    { label: 'History' }
  ], [transId, headlineVal, rootLabel]);

  useEffect(() => {
    Properties.getCompleteYdocHistory(transId).then(response => {
      if (!response) {
        return;
      }

      setUpdates(response.map(u => ({
        value: u.value,
        date: u.ts,
        author: u.author,
        clock: u.clock
      })));
    });
  }, [transId]);

  useEffect(() => {
    const doc = new Y.Doc();
    doc.transact(() => {
      for (const update of updates) {
        if (update.clock > ydocClock) {
          return;
        }

        Y.applyUpdate(doc, update.value);
      }
    });

    setYDoc(doc);
  }, [ydocClock]);

  const json = yDoc?.getMap(rootKey.value).toJSON() ?? {};

  return <div className={'w-100 h-100 px-3 overflow-auto'}>
    <div className={'w-100'}>
      <h1 className="display-6 py-1">{headlineVal} - Explore Mode</h1>
      <div className='d-flex flex-row align-items-center justify-content-between w-100 mb-3'>
        <div><BreadCrumbs segments={updatedBreadcrumb} /></div>
      </div>
    </div>
    <hr className={'m-0 mb-2'} />
    <Container>
      <Row>
        <Col>
          <Select
            value={selectedUpdate}
            onChange={newValue => {
              if (!newValue) {
                return;
              }

              console.log(newValue);
              setSelectedUpdate(newValue);
              setYdocClock(newValue.value.clock);
            }}
            options={updates.map(u => ({
              label: `${formatTimestamp(u.date.getTime())} - ${getAuthorName(u.author)}`,
              value: u
            }))}
          ></Select>
        </Col>
        <Col>
          <Select
            value={rootKey}
            onChange={newValue => {
              setRootKey(newValue);
            }}
            options={[
              { value: PropertyRootKey.Meta, label: 'Meta' },
              { value: PropertyRootKey.Data, label: 'Data' },
              { value: PropertyRootKey.FileTrack, label: 'File Track' }
            ]}
          ></Select>
        </Col>
      </Row>

      <Row className='mt-1'>
        <Col>
          <JsonEditor
            jsonData={json}
            mode={window.ultraRawDogMode ? 'code' : 'tree'}
          />
        </Col>
      </Row>
    </Container>
  </div>;
}

function getAuthorName(author: any) {
  if ('name' in author) {
    return author.name;
  }

  switch (author.type) {
    case 2: // System
      return 'reaforms system';
    case 0: // Unknown
    default:
      return 'Unknown';
  }
}
